import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Grid, { GridItem } from './Grid'
import MetaInfo from '../components/MetaInfo'
import CoverMedia from '../components/CoverMedia'
import { useMediaQuery } from 'react-responsive'
import { formatMyDate } from '../utils/formatMyDate';
import { Transition } from '@headlessui/react'
import { recordListLink, activeLink, recordPlaceholder } from '../styles/record.module.scss'


export default function RecordsFeed(props) {

    const { recordData, className, useList = false } = props;

    const recordGridClassName = `grid-cols-2 md:grid-cols-3 gap-3 gap-y-3`;
    // if (props.gridClassName) recordGridClassName = props.gridClassName;

    const aboveMd = useMediaQuery({ query: '(min-width: 768px)' });

    if (!recordData) return null

    if (useList === true) return <RecordsFeed.List data={recordData} useInitial={props.useInitial ? props.useInitial : false} />

    return (
        <Grid className={className ? [recordGridClassName, className].join(" ") : recordGridClassName}>
                    {
                        recordData.map(record => {

                            const getArtistClassName = [
                                record.node.ltaRecordsSettings.recordArtistSelection !== null ? (
                                    record.node.ltaRecordsSettings.recordArtistSelection.slug
                            ) : (
                                    record.node.slug
                            )
                            ]
        
                            const artistClassName = getArtistClassName;
        
                            const getReleaseYear = new Date(record.node.date);
                            const releaseDateClassName = getReleaseYear.getFullYear();
                            const releaseDateYear = getReleaseYear.getFullYear();
        
                            const releaseItemClassName = [artistClassName, releaseDateClassName].join(" ");
        
                            const determineReleaseName = (r) => {
                           
        
                                if (r.includes(record.node.ltaRecordsSettings.recordArtistSelection.title)) {
                                    return r.replace(" – LP", "").replace(" - LP", "");
                                }
            
                                return r
                            }
        
                            const recordTitle = determineReleaseName(record.node.title);

                            return (

                                <GridItem className={`${releaseItemClassName} relative`} key={record.node.id}>

                                    {record.node.featuredImage &&
                                        <CoverMedia itemData={record.node}>

                                            <MetaInfo metaData={record.node} titleEdit={ recordTitle } />
                                            
                                        </CoverMedia>
                                    }

                                    {
                                        !aboveMd && <Link className={`absolute top-0 right-0 left-0 bottom-0 h-full w-full block z-5`} to={`/records/${releaseDateYear}/${record.node.slug}`}>&nbsp;</Link>

                                    }

                                </GridItem>
                            )
                        })
                    }
                    
                </Grid>
    )
}

const RecordsList = ( { data, className, useInitial = false } ) => {

    // let intialArt= null
    // let initialInfo = ''

    // if (useInitial === true) {
    //     intialArt = getImage(data[0].node?.featuredImage?.node?.localFile)
    //     initialInfo = data[0].node
    // }

    const [coverArt, setCoverArt] = React.useState(null)
    const [info, setInfo] = React.useState('')

    React.useEffect(() => {

        if (useInitial === true) {
            setCoverArt(getImage(data[0].node?.featuredImage?.node?.localFile))
            setInfo(data[0].node)
        }

        return () => {
            setCoverArt(null)
            setInfo(' ')
        }

    }, [useInitial, data])

    // const [showing, isShowing] = React.useState(false)

    // const handleShow = () => {
    //     coverArt === null ? isShowing(false) : isShowing(true)
    // }

    // const placeholder = {
    //     backgroundImage: `url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.15' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
    // }

    const listClassName = `md:pt-0 pt-7 min-h-[60dvh] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-7 md:gap-14 lg:gap-20 xl:gap-28`

    return (
        <div className={className ? [listClassName, className].join(" ") : listClassName}>
            <div className={`col-span-1 md:col-span-1 lg:col-span-4 lg:relative md:self-center`}>
                <div className={`flex flex-col items-start justify-start text-base md:text-xs lg:text-base lg:pl-7 font-mono uppercase tracking-tighter lg:sticky lg:top-7`}>
                    {data.map(record => {

                        const getReleaseDate = new Date(record.node.date);
                        const releaseDateYear = getReleaseDate.getFullYear();
                        // const releaseMonth = getReleaseDate.getMonth()
                        // const releaseDay = getReleaseDate.getDate()

                        // const dateString = releaseMonth + ' ' + releaseDay + ', ' + releaseDateYear

                        const dateString = formatMyDate(new Date(record.node.date))

                        const determineReleaseName = (r) => {
                            
            
                            if (r.includes(record.node.ltaRecordsSettings.recordArtistSelection.title)) {
                                return r.replace(" – LP", "").replace(" - LP", "");
                            }
        
                            return r
                        }

                        const recordTitle = determineReleaseName(record.node.title);

                        const { lpNumber, recordArtistSelection } = record.node.ltaRecordsSettings;

                        const artistName = recordArtistSelection.title

                        const coverImage = getImage(record.node?.featuredImage?.node?.localFile)

                        return (
                            <div key={record.node.id} className={`flex flex-row items-center`}>
                                <RightArrow />
                                <Link className={[recordListLink, info?.id === record.node.id ? activeLink : null].join(" ")} to={`/records/${releaseDateYear}/${record.node.slug}`} title={[`${recordTitle},`, 'by', artistName].join(" ")}
                                    onMouseOver={() => {
                                        setCoverArt(coverImage)
                                        setInfo(record.node)
                                    }
                                    }
                                    // onMouseOut={() => {
                                    //     setCoverArt(null)
                                    //     setInfo('')
                                    // }}
                                    // onBlur={() => {
                                    //     setCoverArt(null)
                                    //     setInfo('')
                                    // }}
                                >
                                {lpNumber}: <em>{recordTitle}</em>, {artistName} <small>({dateString})</small>

                                    {/* {`${lpNumber}: ${recordTitle}, ${artistName} (${dateString})`} */}
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={`md:block hidden md:col-span-1 lg:col-span-3 lg:pl-7 xl:pl-10`}>
                <figure className={`m-0 relative h-0 w-full pt-[100%] border border-color-text overflow-hidden shadow-xl`}>
                <Transition
                    show={coverArt === null ? false : true}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className={`absolute top-0 left-0 right-0 bottom-0 h-full w-full z-[2]`}
                >
                    {coverArt === null ? ( null ) : (
                        <GatsbyImage
                    
                        image={coverArt} 
                        className={`absolute top-0 right-0 left-0 bottom-0 !h-full w-full z-[2] object-cover`}
                        alt={info?.featuredImage?.node?.altText || info.title}
                        layout={`fullWidth`} 
                        loading={`eager`}
                        />
                    ) }
                    
                    {info === '' ? null : <span className={`absolute z-[2] px-2 py-1 bottom-3 top-auto right-3 shadow-lg left-auto bg-black text-contrast text-xxs uppercase italic skew-x-6 skew-y-3`}>{info.title}</span>}
                    </Transition>
                    <div className={[`absolute w-full h-full top-0 left-0 right-0 bottom-0 z-[1] block dark:invert before:opacity-[0.1] after:opacity-[0.1] dark:before:opacity-75 dark:after:opacity-75`, recordPlaceholder].join(" ")}/>
                </figure>
            </div>
        </div>
    )
}

RecordsFeed.List = RecordsList

const RightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-5">
        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
    </svg>

)