export const formatMyDate = (date) => {

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]

      const monthName = months[date.getMonth()];
      const yearName = date.getFullYear();
      const dayName = date.getDate();

      return monthName + ' ' + dayName + ', ' + yearName

}