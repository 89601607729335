import * as React from 'react'
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import Layout from "../components/Layout"
import PageWrapper, { Content, Sidebar } from '../components/PageWrapper'
// import Seo from "../components/Seo"
import Seo2 from '../components/Seo2'
import CoverMedia from '../components/CoverMedia'
import OuterLinks from '../components/OuterLinks'
import { featuredImage, artistNav, artistModule } from '../styles/artist.module.scss'
import { Subheader } from '../components/PageTitles'
import RecordsFeed from '../components/RecordsFeed'

const ArtistTemplate = ({ data }) => {

    const artistScroll = [
        {
            title: data.wpArtist.title
        },
        {
            title: data.wpArtist.title
        },
        {
            title: data.wpArtist.title
        },
        {
            title: data.wpArtist.title
        },
        {
            title: data.wpArtist.title
        },
        {
            title: data.wpArtist.title
        },
        {
            title: data.wpArtist.title
        },
        {
            title: data.wpArtist.title
        }
    ]


    const artistRecordData = data.allWpRelease.edges.filter(rel => rel.node.ltaRecordsSettings.recordArtistSelection.id === data.wpArtist.id);

    // const latestArtistRecordData = artistRecordData.slice(0,3)

    const imageData = getImage(data.wpArtist.featuredImage?.node?.localFile)

    const flattenSlug = data.wpArtist.slug.replaceAll("-", "");

    const artistEmail = `${flattenSlug}@lighterair.com`;

    const [ email, setEmail ] = React.useState('');

    React.useEffect(() => {
      setEmail(artistEmail);
    }, [setEmail, artistEmail])

    // let charLength = data.wpArtist.title.length
    // // console.log('VH: ' + charLength + ' ' + Math.abs((100 - charLength) / charLength))
    // charLength = [Math.abs(((100 - charLength) / charLength) * 1.75).toFixed(2), 'vw'].join("")

    // let charLength = data.wpArtist.title.length
    // charLength = [((100 + (charLength * 2.5)) / charLength).toFixed(2), 'vw'].join("")

    return (
        <Layout>

                <PageWrapper useSidebar={true} scrollData={artistScroll} pauseOnHover={false} clones={6 > data.wpArtist.title.length ? 18 : 12}
                >
                

                <Sidebar>
                    <div className={`md:h-screen md:px-2 md:mx-0 -mx-2 relative z-10`}>
                        <div className={`md:hidden block px-2 border-t border-color-text`}>
                            <h3 className={`uppercase mt-7 mb-1 text-2xl`}>Other Artists</h3>
                        </div>
                        <div className={`w-full md:h-full  md:overflow-y-auto md:overscroll-x-none overflow-x-auto md:space-y-3 md:block flex flex-row md:py-0 py-2 relative ${artistNav}`} style={{scrollbarWidth: '0px'}}>
                            {
                                data.allWpArtist.edges.sort((a,b) => a.node.title > b.node.title).map(allArtist => {
                                    return (
                                        <div key={allArtist.node.id} className={`md:min-h-56 md:h-auto h-full md:first:pt-2 md:last:pb-2 md:px-0 px-2 md:w-full w-2/5 flex-2/5 ${artistModule}`}>
                                            <Link to={`/artists/${allArtist.node.slug}`} title={allArtist.node.title}>
                                                <CoverMedia itemData={allArtist.node} className={`transition-all duration-150 md:hover:-skew-x-1 md:hover:-skew-y-1 md:hover:scale-[0.98] md:transform-gpu full-cover`} />
                                                <h4 className={`m-0 mt-1 uppercase text-center text-sm xl:tracking-tighter leading-none`}> {allArtist.node.title} </h4>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Sidebar>

                <Content className={`space-y-7 xl:space-y-10 mb-10 md/max:overflow-hidden xl:!pl-14 xl:!pr-14`}>
                    <h1 className={`sm:text-[5rem] md:text-[6rem] lg:text-[7rem] text-[3.25rem] leading-none md:leading-[90%] uppercase font-primary -mb-5 mt-0 font-medium`}>{data.wpArtist.title}</h1>
                    {/* <h1 style={{fontSize: charLength, wordSpacing: '-5px'}} className={`leading-none md:leading-[90%] tracking-tight uppercase font-primary -mb-5 mt-0 font-medium xl:block hidden`}>{data.wpArtist.title}</h1> */}

                      {/* <svg className={`xl:block hidden !-mt-24 !-mb-10`} viewBox="0 0 56 18" style={{width: '100%', textTransform: 'uppercase'}} preserveAspectRatio={`xMinYMin meet`}>
                        <text letterSpacing={-1} fontFamily={`var(--font-primary)`} textLength={`133%`} lengthAdjust={`spacingAndGlyphs`} x={`-0.21`} y={`15`}>{data.wpArtist.title}</text>
                      </svg> */}


                    <GatsbyImage 
                    image={imageData}
                    layout={`fullWidth`}
                    className={`w-full border border-color-text ${featuredImage}`}
                    alt={data.wpArtist.featuredImage.node.altText || data.wpArtist.title}
                    loading={`eager`}
                    />

                    <div className={`flex flex-row items-start flex-start flex-wrap !-m-4 pt-7 xl:pt-10`}>
                    {
                        data.wpArtist.socialsInfo &&

                        <OuterLinks useIcon={false} linkData={data.wpArtist.socialsInfo} title={`Follow`} nameContext={data.wpArtist.title} />
                    }
                    {
                        data.wpArtist.streamingLinks &&

                        <OuterLinks useIcon={false} linkData={data.wpArtist.streamingLinks} title={`Stream`} nameContext={data.wpArtist.title} />
                    }
                    {
                        data.wpArtist.purchaseOptions &&

                        <OuterLinks useIcon={false} linkData={data.wpArtist.purchaseOptions} title={`Buy/Support`} nameContext={data.wpArtist.title} />
                    }

                    <div className={`p-4`}>
                      <div className={`flex flex-col justify-start items-start`}>
                        <div className={`mb-3`}>
                        <Subheader>Contact</Subheader>
                        </div>
                            <a className={`font-mono text-xxs tracking-tight uppercase hover:underline underline-offset-[3px] decoration-1`} href={`mailto:${encodeURIComponent(email)}`} title={`Contact ${data.wpArtist.title}`}>
                                {email}
                            </a>
                        </div>
                      </div>
                    

                    </div>

                    {
                      artistRecordData.length !== 0 &&
                      <div className={`flex flex-col justify-start !mt-10 xl:!mt-14`}>
                        <div className={`mb-3`}>
                          <Subheader>LTA Releases</Subheader>
                        </div>
                        <div className={`flex-true`}>
                        <RecordsFeed recordData={artistRecordData} className={`grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 gap-y-3`} />
                        </div>
                        
                      </div>
                    }
                </Content>

                </PageWrapper>
        </Layout>
    )
}

export default ArtistTemplate

export const Head = ( { data } ) => (
  <Seo2 title={data.wpArtist.title} 
        description={data.wpArtist.seo.metaDesc}
        image={getSrc(data.wpArtist.featuredImage?.node?.localFile)}>

        <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "MusicGroup",
              "@id": "https://lighterair.com/artists/${data.wpArtist.slug}",
              "headline": "${data.wpArtist.title}",
              "description": "${data.wpArtist.seo.metaDesc ? data.wpArtist.seo.metaDesc : ''}",
              "image": {
                "@type": "ImageObject",
                "url": "https://lighterair.com${getSrc(data.wpArtist.featuredImage?.node?.localFile)}"
              }
            }`}
        </script>
  </Seo2>
)

export const query = graphql`
  query($id: String) {
    wpArtist(id: { eq: $id }) {
      title
      id 
      slug
      date
      seo {
        metaDesc
      }
      socialsInfo {
        facebookartist
        fieldGroupName
        instagramartist
        twitterartist
        websiteartist
        tiktokartist
      }
      streamingLinks {
        amazonartist
        appleartist
        pandoraartist
        soundcloudartist
        spotifyartist
        youtubeartist
      }
      purchaseOptions {
        bandcampartist
        itunesartist
        merchartist
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                  quality: 89
              )
              
            }
            publicURL
          }
        }
      }
      
    }
    allWpArtist(
        filter: {status: {eq: "publish"}, id: {ne: $id}}
        sort: {fields: title, order: ASC}
      ) {
        edges {
          node {
            title
            slug
            id
            featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(aspectRatio: 1, transformOptions: {cropFocus: CENTER})
                      
                    }
                  }
                }
              }
          }
        }
      }
      allWpRelease(
        filter: {status: {eq: "publish"}}
        sort: {fields: date, order: DESC}
      ) {
        edges {
          node {
            id
            title
            slug
            date
            ltaRecordsSettings {
              recordArtistSelection {
                ... on WpArtist {
                  id
                  title
                  slug
                }
              }
              recordPreorderLink
              recordPurchaseLink
              recordStreamLink
              releaseDate
              lpNumber
            }
            imprints {
                nodes {
                    name
                    id
                    slug
                }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        aspectRatio: 1
                    )
                  }
                }
              }
            }
          }
        }
      }
  }
`